
.projectsLinksDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin: 3rem auto;
    border-bottom: 2px solid rgb(100, 100, 100);;
}

.projectsLink{
    display: flex;
    margin-bottom: 3rem;
    justify-content: space-between;
    overflow-x: hidden;


}

.projectsLinkTextDivLeft{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.projectsLinkTextDivRight{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.projectsLinkTextRight{
    display: flex;
    font-size: 4rem;
    justify-content: right;
    text-align: right;
    color: white;
    font-weight: 400;
    text-shadow: 2px 2px 3px rgb(27, 29, 31);
}


.projectsLinkTextRightMedium{
    display: flex;
    font-size: 2rem;
    justify-content: right;
    text-align: right;
    color: white;
    font-weight: 100;
    text-shadow: 2px 2px 3px rgb(27, 29, 31);
}






.projectsLinkTextLeft{
    display: flex;
    font-size: 3rem;
    justify-content: left;
    color: white;
    font-weight: 100;
    text-shadow: 2px 2px 3px rgb(27, 29, 31);
}

.projectsPhotoDivLeft{
    display: flex;
    width: 50%;
    align-items: flex-end;
    justify-content: right;
    z-index: -1;
}

.projectsPhotoDivRight{
    display: flex;
    width: 50%;
    align-items: flex-start;
    z-index: 3;
}

.projectsPhoto {
    object-fit: fill;
    width: 30rem;
    min-width: 20rem;
    border: 2px solid rgb(100, 100, 100);
}
.projectsPhotoGitHub {
    object-fit: fill;
    width: 30rem;
    min-width: 20rem;
    margin-top: 7rem;
    margin-bottom: 7rem;

}


.projectsTechnologiesUsed {
    display: flex;

}

.technologiesUsedPhoto{
    max-height: 1.7vmax;
    margin-top: 1vmax;
    margin-right: 1vmax;
    object-fit: cover;


}

.arduinoPhoto {
    height: 4rem;
    margin-top: 1rem;
    margin-left: 1rem;
}

.cplusplusPhoto {
    width: 4rem;
}
.marginLeft{
    margin-left: 1vmax;
}



@media screen and (max-width:1050px) {
    .helloText{white-space: break-spaces;}
    .technologiesUsedPhoto{max-height: 3vmax;}
}
