
.navbar{
    background-color: rgb(27, 27, 31);
    border-bottom: 1px solid cornflowerblue;
    position: sticky;
    z-index: 20;
    top: 0;
}

.logoAndPortfolioText{
    align-items: center;
    display: flex;
    justify-content: space-between;
}


.portfolioText{
    color: white;
    font-size: 2rem;
    margin-left: 1rem;
    font-weight: 100;
}

.navbarContents{
    height: 4rem;
    max-width: 80vw;
    margin: auto auto;
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 5;
}

.material-symbols-outlined {
    font-size: 3rem;
    overflow-y: hidden;
    color: white;
}

.logoImg{
    object-fit:cover;
    width: 2rem;
}

.desktopMenu{
    display: flex;
    align-items: center;
    justify-items: center;
    overflow-x: hidden;
}

.desktopMenuNavLink{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem;
    border: 2px inset transparent;
    cursor: pointer;
    color: white;
    margin: 0 0.5rem;
}

.active.desktopMenuNavLink {

    border-bottom: 1px inset cornflowerblue;

}

.desktopMenuNavLink:hover {
    color: white;
    background-size: contain;
}


.mobileMenuVisible {
    display: flex;
    position: absolute;
    top: 4rem;
    right: 1rem;
    font-size: 2rem;
    flex-direction: column;
    border-radius: 1rem;
    justify-content: center;
    border: solid 1px cornflowerblue;
    padding: 5px;
    text-align: center;
    background-color: rgb(27, 27, 31);

}

.openMobileMenuButton {
    border: none;
}

.mobileMenuTriangle {
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-bottom-color: cornflowerblue;
    position: absolute;
    top: -1.3rem;
    right: 0.8rem;
}


.mobileMenuNavLink:hover {
    color: rgb(30, 30, 30);
    border-radius: 0.5rem;

    background-size: contain;
}

.mobileMenuNavLink {
    width: 100%;
    color: white;
    border-radius: 2rem;
    padding: 0.2rem 0.5rem;
    font-size: 1.5rem;
    align-content: center;
}

.active.mobileMenuNavLink {
    color: white;
    border-radius: 0.75rem;
    background-size: contain;
    border: 0.2vmax solid cornflowerblue;
}




.mobileMenuInvisible {
    display: none;

}

.mobileMenu {
    display: none;
}

@media screen and (max-width:1050px) {
    .desktopMenu{display:none;}
    .mobileMenu{display: flex;}
    .navbarContents{max-width: 100vw;}
}
