*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;
}

body{
    font-family: 'Ubuntu', sans-serif;
    color: white;
    overflow: hidden;
    overflow-y: auto;
    background: rgb(18, 18, 23);


}

.page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

}


ul {
    padding-left: 2vmax;
    
}

li {
    margin-top: 1vmax;

}




.pageContent{
    margin: 1rem auto;
    max-width: 80vw;
    background: rgb(27, 27, 31);
    padding: 1vmax 1vmax;

    border-radius: 1vmax;
}

@media screen and (max-width:1000px) {
    .pageContent{max-width: 90vw;}
}


@media screen and (min-width:1000px) {
    li{margin-top: 0.5vmax;}
}

