.footer{
    height: 2rem;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    font-size: 1vmax;
    font-weight: 100;
    color: rgb(100, 100, 100);
    background-color: rgb(27, 27, 31);
    text-align: center;
    border-top: 0.1vmax solid cornflowerblue;
    padding: 1.5vmax;

}
