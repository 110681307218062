

.aboutTopDiv {
    display: flex;
    justify-content: center;
    padding: 1vmax;

}

.aboutMidDiv {
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding: 1vmax;
}

.aboutTitleText {
    font-size: 2.5vmax;
    font-weight: 400;
    border-bottom: 0.1vmax cornflowerblue solid;
    padding-bottom: 4px;
    margin-top: 1vmax;
}

.aboutLinkText {
    color: cornflowerblue;
    font-size: 1.5vmax;
    font-weight: 400;
}

.aboutLinkText:hover {
    border-bottom: 1px solid cornflowerblue;
}


.aboutTopText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1vmax;
}

.thinTxt {
    font-size: 1.5vmax;
    font-weight: 100;
    color: rgb(200, 200, 200);
}

.midTxt {
    font-size: 1.5vmax;
    font-weight: 600;
    color: rgb(220, 220, 220);
}


.boldTxt {
    font-size: 1.7vmax;
    font-weight: 600;
    color: rgb(220, 220, 220);
    margin-top: 1vmax;
}


.aboutPortraitPhoto {
    width: 50%;
    max-width: 40%;
    margin: 1vmax;
    background: rgb(18, 18, 23);
    border-radius: 100%;
    border: 0.14vmax cornflowerblue solid;

}


@media screen and (min-width:1050px) {
    .aboutTitleText{font-size: 1.8vmax;}
    .aboutLinkText{font-size: 1.2vmax;}
    .titleTxt{font-size: 1.5vmax;}
    .boldTxt{font-size: 1.4vmax;}
    .midTxt{font-size: 1.2vmax;}
    .thinTxt{font-size: 1.15vmax;}
    .aboutPortraitPhoto{width: 30%;}
}

