
.home{
    min-height: 100%;
}

.helloText{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    color: white;
}


.helloIm{
    font-size: 2rem;
    font-weight: 400;
    white-space: nowrap;
    color: white;
    
}

.myName{
    font-size: 4rem;
    font-weight: 400;
    text-shadow: 2px 2px 3px rgb(27, 29, 31);
    
}

.softDev{
    font-size: 1.8rem;
    font-weight: 100;
    white-space: nowrap;
    color: rgb(221, 221, 221);
    text-shadow: 2px 2px 3px rgb(27, 29, 31);
}

.softDevWrap{
    font-size: 1.8rem;
    font-weight: 100;
}

.homeDivInner {

    display: flex;
    flex-direction: column;
    align-items: center;



    
}

.homeDivMid {
  
    display: flex;
    margin: 1rem;
    justify-items: center;

    align-items: center;
}


.homeDivOuter {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;





}

.homeLinksDiv {
    padding-bottom: 2vmax;
}


.homeLinkTextDivLeft{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.homeLinkTextDivRight{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.homeLinkTextRight{
    display: flex;
    font-size: 3rem;
    justify-content: right;
    text-align: right;
    color: white;
    font-weight: 100;
    text-shadow: 2px 2px 3px rgb(27, 29, 31);
}


.homeLinkTextLeft{
    display: flex;
    font-size: 3rem;
    justify-content: left;
    color: white;
    font-weight: 100;
    text-shadow: 2px 2px 3px rgb(27, 29, 31);
}

.homePhotoDivLeft{
    display: flex;
    width: 50%;
    align-items: flex-end;
    justify-content: right;
    z-index: 3;
}

.homePhotoDivRight{
    display: flex;
    width: 50%;
    align-items: flex-start;
    z-index: 3;
}

.homePhotoTxt {
    background: rgb(18, 18, 23);
    width: 33vmax;
    height: 33vmax;
    justify-content: center;
    align-content: center;
    display: grid;
    object-fit: cover;
    border: 0.14vmax solid cornflowerblue;
    border-radius: 2vmax;
    margin-top: 1rem;

    font-size: 1.7vmax;
    font-weight: 600;
    color: rgb(220, 220, 220);
}


.homePhotoMail {
    background: rgb(18, 18, 23);
    width: 33vmax;
    height: 33vmax;
    justify-content: center;
    align-content: center;
    display: grid;
    object-fit: cover;
    border: 0.14vmax solid cornflowerblue;
    border-radius: 2vmax;
    margin-top: 1rem;

    font-size: 12vmax;
    font-weight: 600;
    color: rgb(220, 220, 220);
}

.homeBigFont{
    font-size: 5vmax;
}

.homePhoto {

    width: 33vmax;
    height: 33vmax;
    object-fit: cover;
    border: 0.14vmax solid cornflowerblue;
    border-radius: 2vmax;
    margin-top: 1rem;
    background: rgb(18, 18, 23);

}

.homePhotoFit {

    width: 33vmax;
    height: 33vmax;
    object-fit: contain;
    border: 0.14vmax solid cornflowerblue;
    border-radius: 2vmax;
    margin-top: 1rem;
    background: rgb(18, 18, 23);

}


.pongPhoto {
    object-fit: fill;
    width: 30rem;
    min-width: 20rem;
    border: 2px solid gray;
}

.portraitPhoto {
    object-fit: fill;
    width: 30rem;
    min-width: 20rem;


}





@media screen and (max-width:1050px) {
    .helloText{white-space: break-spaces;}
}

@media screen and (min-width:1050px) {
    .homePhoto{width: 30vmax; height: 30vmax;}
    .homePhotoTxt{width: 30vmax; height: 30vmax; font-size: 1.3vmax;}
    .homePhotoMail{width: 30vmax; height: 30vmax; font-size: 12vmax;}
    .homePhotoFit{width: 30vmax; height: 30vmax;}
}
